import React from 'react'
import PropTypes from 'prop-types'
import _noop from 'lodash/noop'

import ButtonStyled from './styles'
import { KINDS } from '../../../constants/kinds'
import { SIZES } from '../../../constants/sizes'
import { Typo6 } from '../../Typography'

const Button = ({
  children,
  onClick,
  ...rest
}) => (
  <ButtonStyled
    {...rest}
    onClick={onClick}
  >
    <Typo6>{children}</Typo6>
  </ButtonStyled>
)

Button.defaultProps = {
  kind: 'filled',
  size: 'medium',
  type: 'button',
  onClick: _noop,
}

Button.propTypes = {
  kind: PropTypes.oneOf(Object.keys(KINDS)),
  size: PropTypes.oneOf(Object.keys(SIZES)),
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default Button
