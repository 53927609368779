import React from 'react'
import PropTypes from 'prop-types'

import LogoIcon from '../Icons/Logo'
import LogoSignIcon from '../Icons/LogoSign'
import {
  LogoWrapperStyled,
  LogoIconStyled,
  LogoLabelStyled,
  typesLogo,
} from './styles'

const Logo = ({
  type,
}) => (
  <LogoWrapperStyled
    href="/"
    type={type}
    aria-label="Logo"
  >
    <LogoIconStyled>
      <LogoIcon />
    </LogoIconStyled>
    <LogoLabelStyled>
      <LogoSignIcon />
    </LogoLabelStyled>
  </LogoWrapperStyled>
)

Logo.defaultProps = {
  type: typesLogo.light,
}

Logo.propTypes = {
  type: PropTypes.oneOf(Object.keys(typesLogo)),
}

export default Logo
