import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '9',
  height: '9',
  viewBox: '0 0 9 9',
  body: (
    <path
      // eslint-disable-next-line max-len
      d="M9 .906L8.094 0 4.5 3.594.906 0 0 .906 3.594 4.5 0 8.094.906 9 4.5 5.406 8.094 9 9 8.094 5.406 4.5z"
      key="key-cross"
    />
  ),
  displayName: 'Cross',
})
