import styled, { css } from 'styled-components'

const makeSvgIcon = ({
  width,
  height,
  viewBox,
  body,
  displayName = 'SvgIcon',
}) => {
  const getDimensions = () => ({
    height,
    width,
  })

  const getDimensionsCss = () => css`
    width: ${width}px;
    height: ${height}px;
  `

  const Image = styled.svg`
    ${({ noStyles }) => (!noStyles ? getDimensionsCss() : null)}
  `

  const defaultProps = {
    children: [
      body,
    ],
    viewBox,
  }

  return Object.assign(Image, {
    getDimensions,
    getDimensionsCss,
    defaultProps,
    displayName,
  })
}

export {
  // eslint-disable-next-line import/prefer-default-export
  makeSvgIcon,
}
