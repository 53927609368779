import styled from 'styled-components'

import media from '../../../common/MediaQueries'
import { fieldReset } from '../../../common/Mixins'

const TextareaStyled = styled.textarea`
  ${fieldReset}
  height: ${({ theme }) => theme.form.fieldTextareaPhoneHeight};
  padding: ${({ theme: { gutter } }) => `
    ${gutter.gutter10} 
    ${gutter.gutter16}
  `};
  resize: vertical;
  
  @media (${media.phone}) {
    height: ${({ theme }) => theme.form.fieldTextareaHeight};    
  }
`

export default TextareaStyled
