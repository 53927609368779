import styled from 'styled-components'
import { Link } from 'react-scroll'

import media from './MediaQueries'
import { transition } from './Mixins'

const InnerStyled = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.innerWidth};
  margin: 0 auto;
  padding-right: ${({ theme }) => theme.gutter.gutter16};
  padding-left: ${({ theme }) => theme.gutter.gutter16};

  @media (${media.phone}) {
    padding-right: ${({ theme }) => theme.gutter.gutter32};
    padding-left: ${({ theme }) => theme.gutter.gutter32};
  }
`

const MsgStyled = styled.p`
  padding: ${({ theme }) => theme.gutter.gutter16};
  text-align: center;
  font-style: italic;
`

const ContentWrapperStyled = styled.div`
  h1 {
    text-align: center;
    margin: ${({ theme }) => theme.gutter.gutter48} 0;
  }

  h1,
  h2 {
    color: ${({ theme }) => theme.colors.arctic};
    text-transform: uppercase;
  }

  a {
    ${transition}
    color: ${({ theme }) => theme.colors.cobalt};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.colors.arctic};
    }
  }

  ol + ul,
  ul + ol,
  h2 + ul,
  h2 + ol,
  h2 + p,
  p + p,
  p + ul,
  ul + p,
  p + ol,
  ol + p,
  li + li {
    margin-top: ${({ theme }) => theme.gutter.gutter24};
  }

  ul + h2,
  ol + h2,
  p + h2 {
    margin-top: ${({ theme }) => theme.gutter.gutter32};
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`

const CustomLinkStyled = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  &:hover {
    color: ${({ theme }) => theme.colors.navy};
  }
`

const SimpleLinkStyled = styled.a`
  display: inline-flex;
  ${transition}

  &:hover {
    opacity: 0.6;
  }
`

const SimpleNumberedListStyled = styled.ol`
  margin: 0 0 0 ${({ theme }) => theme.gutter.gutter40};
  padding: 0;
  list-style-type: decimal;
`

const SimpleDottedListStyled = styled.ul`
  margin: 0 0 0 ${({ theme }) => theme.gutter.gutter40};
  padding: 0;
  list-style-type: disc;
`

const SimpleListItemStyled = styled.li``

const CookieSettingsStyled = styled.div`
  margin-bottom: ${({ theme }) => theme.gutter.gutter24};

  &::after {
    display: block;
    content: '';
    clear: both;
    height: 0;
  }
`

const CookiePolicyStyled = styled.div`
  margin-bottom: ${({ theme }) => theme.gutter.gutter24};
  font-size: ${({ theme }) => theme.typography.textStyles.typo4.fontSize[0]};
  line-height: ${({ theme }) => theme.typography.textStyles.typo4.lineHeight};

  .optanon-cookie-policy-group-name {
    font-size: ${({ theme }) => theme.typography.headings.heading2.fontSize[0]};
    line-height: ${({ theme }) =>
      theme.typography.headings.heading2.lineHeight[0]};
  }

  .optanon-cookie-policy-group + .optanon-cookie-policy-group {
    margin-top: ${({ theme }) => theme.gutter.gutter24};
  }

  .optanon-cookie-policy-group-cookies-list {
    margin-left: ${({ theme }) => theme.gutter.gutter40};
    padding: 0;
    list-style-type: disc;
  }

  li + li {
    margin-top: ${({ theme }) => theme.gutter.gutter8};
  }

  @media (${media.phone}) {
    .optanon-cookie-policy-group-name {
      font-size: ${({ theme }) =>
        theme.typography.headings.heading2.fontSize[1]};
    }
  }

  @media (${media.tablet}) {
    .optanon-cookie-policy-group-name {
      line-height: ${({ theme }) =>
        theme.typography.headings.heading2.lineHeight[2]};
    }
  }
`

const HiddenItemStyled = styled.div`
  color: transparent;
  height: 0;
`

export {
  InnerStyled,
  MsgStyled,
  ContentWrapperStyled,
  SimpleLinkStyled,
  SimpleNumberedListStyled,
  SimpleDottedListStyled,
  SimpleListItemStyled,
  CustomLinkStyled,
  CookieSettingsStyled,
  CookiePolicyStyled,
  HiddenItemStyled,
}
