import React from 'react'
import PropTypes from 'prop-types'

import {
  FieldStyled,
  FieldControlStyled,
  FieldLabelStyled,
} from '../styles'
import InputStyled from './styles'
import { Typo4 } from '../../Typography'

const Input = ({
  label,
  id,
  name,
  disabled,
  input: { name: inputName = name, ...inputProps },
  ...props
}) => (
  <FieldStyled>
    <FieldLabelStyled htmlFor={id} disabled={disabled}>
      <Typo4>{label}</Typo4>
    </FieldLabelStyled>
    <FieldControlStyled>
      <InputStyled
        name={inputName}
        {...props}
        {...inputProps}
        disabled={disabled}
        id={id}
        required
      />
    </FieldControlStyled>
  </FieldStyled>
)

Input.defaultProps = {
  name: '',
  input: {},
  type: '',
  disabled: false,
}

Input.propTypes = {
  name: PropTypes.string,
  input: PropTypes.objectOf(PropTypes.any),
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
}

export default Input
