import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'

import media from '../../common/MediaQueries'

const ContactSectionStyled = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: 100%;
`

const ContactSectionMediaStyled = styled(Img)`
  display: none;

  @media (${media.tablet}) {
    display: block;
    width: 100%;
  }
`

const ContactSectionFormStyled = styled.div`
  background: ${({ theme }) => theme.colors.darkNavy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; 
`

export {
  ContactSectionStyled,
  ContactSectionMediaStyled,
  ContactSectionFormStyled,
}
