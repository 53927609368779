import React from 'react'
import PropTypes from 'prop-types'

import {
  FieldStyled,
  FieldControlStyled,
  FieldLabelStyled,
} from '../styles'
import TextareaStyled from './styles'
import { Typo4 } from '../../Typography'

const Textarea = ({
  label,
  id,
  name,
  disabled,
  input: { name: textareaName = name, ...inputProps },
  ...props
}) => (
  <FieldStyled>
    <FieldLabelStyled htmlFor={id} disabled={disabled}>
      <Typo4>{label}</Typo4>
    </FieldLabelStyled>
    <FieldControlStyled>
      <TextareaStyled
        name={textareaName}
        {...props}
        {...inputProps}
        disabled={disabled}
        id={id}
      />
    </FieldControlStyled>
  </FieldStyled>
)

Textarea.defaultProps = {
  name: '',
  input: {},
  type: '',
  disabled: false,
}

Textarea.propTypes = {
  name: PropTypes.string,
  input: PropTypes.objectOf(PropTypes.any),
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
}

export default Textarea
