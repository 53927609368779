import React from 'react'

import {
  ContactDetailsStyled,
  ContactDetailsMailStyled,
  ContactDetailsPhoneStyled,
} from './styles'
import Message from '../Icons/Message'
import Phone from '../Icons/Phone'

const ContactDetails = () => (
  <ContactDetailsStyled>
    <ContactDetailsPhoneStyled>
      <Phone />
      +44 (0)20 3965 4500
    </ContactDetailsPhoneStyled>
    <ContactDetailsMailStyled href="mailto:info@autoloadit.com">
      <Message />
      info@autoloadit.com
    </ContactDetailsMailStyled>
  </ContactDetailsStyled>
)

export default ContactDetails
