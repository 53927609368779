import styled, { css } from 'styled-components'
import { Link as ScrollLink } from 'react-scroll'
import { Link } from 'gatsby'

import media, { customMedia } from '../../common/MediaQueries'
import { LogoLabelStyled } from '../../components/Logo/styles'
import {
  transition,
  logoPosition,
  borderDecor,
  baseLink,
} from '../../common/Mixins'
import {
  HamburgerStyled,
  HamburgerLineStyled,
} from '../../components/Hamburger/styles'
import { ContactDetailsStyled } from '../../components/ContactDetails/styles'

const MenuLinkStyled = styled(ScrollLink)`
  ${baseLink}
`

const MenuRouterLinkStyled = styled(Link)`
  ${baseLink}
`

const MenuStyled = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: ${({ theme }) => theme.gutter.gutter32};
  z-index: 100;
  height: ${({ theme }) => theme.sizes.menuHeightPhone};;
  transform: translateZ(0);
  background: transparent;
  ${transition({ property: 'background' })}
  ${borderDecor}

  ${({ isOpened }) => isOpened && css`
    ${LogoLabelStyled} {
      color: ${({ theme }) => theme.colors.white};
    }
  `}

  ${({ isSticky }) => isSticky && css`
    background: ${({ theme }) => theme.colors.darkNavy};

    ${ContactDetailsStyled},
    ${LogoLabelStyled} {
      color: ${({ theme }) => theme.colors.white};
    }

    ${HamburgerStyled} {
      ${({ isOpened }) => isOpened && css`
        ${HamburgerLineStyled} {
          background: transparent;
        }
      `}
    }

    ${HamburgerLineStyled} {
      background: ${({ theme }) => theme.colors.white};

      &::before,
      &::after {
        background: ${({ theme }) => theme.colors.white};
      }
    }
  `}

  @media (${media.phone}) {
    height: ${({ theme }) => theme.sizes.menuHeight};
  }

  @media (${media.tablet}) {
    color: ${({ theme }) => theme.colors.darkNavy};

    ${({ isSticky }) => isSticky && css`
      ${MenuLinkStyled},
      ${MenuRouterLinkStyled} {
        color: ${({ theme }) => theme.colors.white};

        &.active,
        &:hover {
          color: ${({ theme }) => theme.colors.arctic};
        }
      }
    `}
  }
`

const MenuWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-left: ${({ theme }) => theme.gutter.gutter16};
  padding-right: ${({ theme }) => theme.gutter.gutter20};

  @media (${media.phone}) {
    padding-left: ${({ theme }) => theme.gutter.gutter32};
    padding-right: ${({ theme }) => theme.gutter.gutter32};
  }

  @media (${media.tablet}) {
    padding-left: ${({ theme }) => theme.gutter.gutter64};
    padding-right: ${({ theme }) => theme.gutter.gutter82};
  }

  @media (${customMedia.contactBreakpoint}) {
    align-items: center;
  }

`

const MenuContentBoxInnerStyled = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  margin-top: ${({ theme }) => theme.gutter.gutter96};
  margin-bottom: ${({ theme }) => theme.gutter.gutter96};
  max-height: calc(100vh
    - ${({ theme }) => theme.gutter.gutter96}
    - ${({ theme }) => theme.gutter.gutter96});

  @media (${media.tablet}) {
    display: flex;
    max-height: none;
    margin: 0;
  }
`

const MenuContentBoxNavStyled = styled.div`
  position: relative;
  padding: ${({ theme: { gutter } }) => `
    0
    ${gutter.gutter32}
    ${gutter.gutter76}
  `};

  display: flex;
  flex-direction: column-reverse;

  @media (${media.tablet}) {
    flex-direction: row;
    align-items: center;
    padding: 0;
  }
`

const MenuContentBoxStyled = styled.div`
  position: fixed;
  top: 0;
  right: calc(-150vw - 1px);
  width: calc(100vw + 1px);
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
  visibility: hidden;
  transition: 500ms cubic-bezier(0.19,1,0.22,1) 170ms;
  transform: translateX(0);
  background-color: ${({ theme }) => theme.colors.darkNavy};
  z-index: 2;
  opacity: 0;

  ${({ isOpened }) => isOpened && css`
    transform: translateX(calc(-150vw - 1px));
    visibility: visible;
    opacity: 1;


    ${MenuContentBoxInnerStyled} {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  `}

  @media (${media.tablet}) {
    position: static;
    background: transparent;
    width: auto;
    height: auto;
    transform: none;
    transition: none;
    opacity: 1;
    visibility: visible;
  }
`

const MenuNavListStyled = styled.ul`
  display: inline-flex;
  flex-direction: column;
  width: 100%;

  @media (${media.tablet}) {
    position: relative;
    flex-direction: row;

    transform: translateX(0%);
    opacity: 1;
  }
`

const MenuNavListItemStyled = styled.li`
  text-transform: uppercase;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.gutter.gutter20};
  }

  @media (${media.phone}) {
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.gutter.gutter70};
    }
  }

  @media (${media.tablet}) {
    margin-right: calc(${({ theme }) => theme.gutter.gutter16} * 2);
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`

const MenuLogoStyled = styled.div`
  ${logoPosition}
  z-index: 10;
`

const MenuNavWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.sizes.menuNavWrapperHeight};

  @media (${media.tablet}) {
    height: auto;
  }

  @media (${media.desktop}) {
    margin-left: ${({ theme }) => theme.gutter.gutter32};
  }
`

const MenuActionCommonStyled = styled.div`
  z-index: 10;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.gutter.gutter20};

  > button {
    min-width: ${({ theme }) => theme.button.menuButtonWidth};
  }

  @media (${media.phone}) {
    display: none;
    margin-bottom: 0;
  }

  @media (${media.tablet}) {
    position: static;
    display: block;
    transform: none;
  }
`

const MenuActionTabletStyled = styled.div`
  display: none;

  @media (${media.phone}) {
    display: block;
    z-index: 10;
    margin-right: ${({ theme }) => theme.gutter.gutter20};
  }

  @media (${media.tablet}) {
    display: none;
  }
`

const MenuContactDetailsStyled = styled.div`
  display: none;

  @media (${customMedia.contactBreakpoint}) {
    display: block;
  }
`

export {
  MenuStyled,
  MenuWrapperStyled,
  MenuLogoStyled,
  MenuNavListStyled,
  MenuNavListItemStyled,
  MenuLinkStyled,
  MenuRouterLinkStyled,
  MenuNavWrapperStyled,
  MenuContentBoxStyled,
  MenuContentBoxInnerStyled,
  MenuContentBoxNavStyled,
  MenuActionTabletStyled,
  MenuActionCommonStyled,
  MenuContactDetailsStyled,
}
