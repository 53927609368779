import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '500',
  height: '375',
  viewBox: '0 0 500 375',
  body: (
    <path
      // eslint-disable-next-line max-len
      d="M453.1 0H46.9C21 0 0 21 0 46.9v281.2C0 354 21 375 46.9 375h406.2c25.8 0 46.9-21 46.9-46.9V46.9C500 21 479 0 453.1 0zm0 31.2c2.1 0 4.1.4 6 1.2L250 213.7 40.9 32.5c1.8-.8 3.9-1.2 6-1.2l406.2-.1zm0 312.5H46.9c-8.6 0-15.6-7-15.6-15.6V65.5l208.5 180.7c2.9 2.5 6.6 3.8 10.2 3.8s7.3-1.3 10.2-3.8L468.8 65.5v262.6c-.1 8.6-7.1 15.6-15.7 15.6z"
      key="key-message"
    />
  ),
  displayName: 'Message',
})
