import styled, { css } from 'styled-components'

const FieldStyled = styled.div``

const FieldControlStyled = styled.div`
  position: relative;
  z-index: 1;
`

const FieldLabelStyled = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.white};
  margin-left: ${({ theme }) => theme.gutter.gutter14};
  margin-bottom: ${({ theme }) => theme.gutter.gutter8};

  ${({ disabled }) => disabled && css`
    opacity: .4;
    pointer-events: none;
  `}
`

export {
  FieldStyled,
  FieldControlStyled,
  FieldLabelStyled,
}
