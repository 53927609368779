import styled, { keyframes, css } from 'styled-components'

import { decorHeading } from '../../common/Mixins'
import media from '../../common/MediaQueries'

const rotate360 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const ContactFormWrapperStyled = styled.div`
  margin: ${({ theme }) => theme.gutter.gutter112} auto
  ${({ theme }) => theme.gutter.gutter128} auto;
  padding: 0 ${({ theme }) => theme.gutter.gutter40};
  max-width: ${({ theme }) => theme.sizes.contactFormWidth};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh 
  - ${({ theme }) => theme.gutter.gutter112} 
  - ${({ theme }) => theme.gutter.gutter128});

  ${({ isSent }) => isSent && css`
    height: auto;
  `}

  @media (${media.phone}) {
    height: auto;
    margin-top: ${({ theme }) => theme.gutter.gutter76};
    margin-bottom: ${({ theme }) => theme.gutter.gutter48};
  }
`

const ContactFormStyled = styled.form`
  @media (${media.phone}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const ContactHeaderStyled = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  ${decorHeading({ borderColor: '#19CEFF' })};
`

const ContactDescStyled = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.gutter.gutter10};

  br {
    display: none;
  }

  @media (${media.phone}) {
    br {
      display: inline;
    }
  }
`

const ContactFieldsStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const ContactFieldStyled = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.gutter.gutter16};
`

const ContactFieldHalfStyled = styled(ContactFieldStyled)`
  @media (${media.phone}) {
    width: calc(50% - ${({ theme }) => theme.gutter.gutter20});
  }
`

const ContactActionStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.gutter.gutter32};
  margin-bottom: ${({ theme }) => theme.gutter.gutter16};
  button {
    width: 100%;
  }

  @media (${media.phone}) {
    margin-top: ${({ theme }) => theme.gutter.gutter56};    
    button {
      width: auto;
    }
  }
`

const ContactSentMsgStyled = styled.div`
  @media (${media.phone}) {
    flex-direction: column;
    justify-content: center;
  }
`

const ContactLoaderStyled = styled.div`
  position: relative;
  display: inline-block;
  width: ${({ theme }) => theme.sizes.contactFormLoaderSize};
  height: ${({ theme }) => theme.sizes.contactFormLoaderSize};

  &::after {
    content: '';
    display: block;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    border: 6px solid ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.white}
      transparent
      ${({ theme }) => theme.colors.white}
      transparent;
    animation: ${rotate360} 1.5s linear infinite;
  }
`

export {
  ContactFormWrapperStyled,
  ContactFormStyled,
  ContactHeaderStyled,
  ContactDescStyled,
  ContactFieldsStyled,
  ContactFieldHalfStyled,
  ContactFieldStyled,
  ContactActionStyled,
  ContactSentMsgStyled,
  ContactLoaderStyled,
}
