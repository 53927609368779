import React from 'react'
import PropTypes from 'prop-types'

import {
  SelectStyled,
  SelectIconStyled,
  SelectOptionStyled,
} from './styles'
import {
  FieldStyled,
  FieldControlStyled,
  FieldLabelStyled,
} from '../styles'
import ArrowDownIcon from '../../Icons/ArrowDown'
import { Typo4 } from '../../Typography'

const Select = ({
  label,
  options,
  name,
  disabled,
  input: {
    name: inputName = name,
    label: inputLabel = label,
    options: inputOptions = options,
    ...inputProps
  },
  ...props
}) => (
  <FieldStyled>
    <FieldLabelStyled disabled={disabled}>
      <Typo4>{inputLabel}</Typo4>
    </FieldLabelStyled>
    <FieldControlStyled>
      <SelectStyled
        name={inputName}
        {...props}
        {...inputProps}
        disabled={disabled}
      >
        {inputOptions.map(({ label: optionLabel, value }) => (
          <SelectOptionStyled
            key={`${optionLabel}${value}`}
            value={value}
          >
            {optionLabel}
          </SelectOptionStyled>
        ))}
      </SelectStyled>
      <SelectIconStyled>
        <ArrowDownIcon />
      </SelectIconStyled>
    </FieldControlStyled>
  </FieldStyled>
)

Select.defaultProps = {
  options: [],
  name: '',
  input: {},
  disabled: false,
}

Select.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  name: PropTypes.string,
  input: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
}

export default Select
