import styled from 'styled-components'

import media from '../../../common/MediaQueries'
import { fieldReset } from '../../../common/Mixins'

const InputStyled = styled.input`
  ${fieldReset}
  height: ${({ theme }) => theme.form.fieldPhoneHeight};
  padding: ${({ theme: { gutter } }) => `
    ${gutter.gutter10} 
    ${gutter.gutter16}
  `};
  
  @media (${media.phone}) {
    height: ${({ theme }) => theme.form.fieldHeight};    
  }
`

export default InputStyled
