import styled from 'styled-components'
import {
  lineHeight,
  fontSize,
  color,
  textStyle,
  fontWeight,
  textAlign,
  system,
} from 'styled-system'

const StyledDynamicComponent = styled.p`
  ${lineHeight}
  ${fontSize}
  ${color}
  ${textStyle}
  ${fontWeight}
  ${textAlign}
  ${system({
    textTransform: true,
  })}
`

export default StyledDynamicComponent
