import styled from 'styled-components'

import media from '../../../common/MediaQueries'
import { fieldReset, icon } from '../../../common/Mixins'

const SelectIconStyled = styled.span`
  pointer-events: none;
  position: absolute;
  top: calc(${({ theme }) => theme.form.fieldHeight} / 2);
  right: ${({ theme }) => theme.gutter.gutter20};
  display: flex;
  flex-shrink: 0;
  transform: translateY(-50%);
  
  svg {
    ${icon}
    z-index: inherit;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.blue};
  }
`

const SelectStyled = styled.select`
  ${fieldReset}
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  height: ${({ theme }) => theme.form.fieldPhoneHeight};
  cursor: pointer;
  padding: ${({ theme: { gutter } }) => `
    ${gutter.gutter10} 
    ${gutter.gutter48}
    ${gutter.gutter10}
    ${gutter.gutter16}
  `};

  &:disabled + ${SelectIconStyled} {
    opacity: .4;
    pointer-events: none;
  }
  

  @media (${media.phone}) {
    height: ${({ theme }) => theme.form.fieldHeight};    
  }  
`

const SelectOptionStyled = styled.option``

export {
  SelectStyled,
  SelectOptionStyled,
  SelectIconStyled,
}
