/* eslint-disable max-len */
import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '34',
  height: '34',
  viewBox: '0 0 34 34',
  body: (
    <g key="key-logo">
      <path
        d="M8.484 21.065c-.049-.329-.1-.661-.135-1-4.6-.962-7.4-2.828-7.4-4.606.002-1.46 1.775-2.882 4.751-3.882l-.8.7a.482.482 0 00-.048.675.472.472 0 00.671.048l3.12-2.73L4.6 9.388a.479.479 0 00-.2.936l1.229.268C2.068 11.751 0 13.511 0 15.46c0 2.5 3.418 4.605 8.484 5.6"
      />
      <path
        d="M24.384 10.811a.482.482 0 00.4.578c4.708.987 7.632 2.7 7.632 4.48 0 2.557-6.467 5.295-16.093 5.295-1.57 0-3.055-.074-4.442-.206.054.337.114.664.178.982 1.359.122 2.786.188 4.263.188 9.626 0 17.166-2.749 17.166-6.258 0-2.281-3.163-4.306-8.461-5.417a.585.585 0 00-.119-.014.528.528 0 00-.524.372"
      />
      <path
        d="M20.685 24.855a.468.468 0 00-.114-.014.5.5 0 00-.483.407c-1.021 4.611-2.79 7.476-4.618 7.476-2.64 0-5.466-6.363-5.466-15.834S12.83 1.055 15.47 1.055s5.466 6.362 5.466 15.834q0 .484-.012.967l-.5-1.207a.486.486 0 00-.656-.269.541.541 0 00-.253.7l1.754 4.2 2.112-4.011a.546.546 0 00-.191-.718.482.482 0 00-.676.2l-.593 1.126q.012-.494.012-.988C21.931 7.419 19.093 0 15.47 0S9.01 7.419 9.01 16.89s2.838 16.889 6.46 16.889c2.349 0 4.436-3.1 5.585-8.289a.53.53 0 00-.37-.634"
      />
    </g>
  ),
  displayName: 'Logo',
})
