import styled, { css } from 'styled-components'

import { transition, icon } from '../../common/Mixins'
import media from '../../common/MediaQueries'

export const typesLogo = {
  light: 'light',
  dark: 'dark',
  mixedDark: 'mixedDark',
  mixedLight: 'mixedLight',
}

const LogoIconStyled = styled.div`
  margin-right: ${({ theme }) => theme.gutter.gutter8};
  display: flex;
  flex-shrink: 0;

  svg {
    ${icon}
    font-size: 32px;
  }
`

const LogoLabelStyled = styled.div`
  display: flex;
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing50};
  ${transition}

  svg {
    ${icon}
    width: 14.75em;
    font-size: 11px;
  }

  @media (${media.tablet}) {
    svg {
      font-size: 13px;
    }
  }
`

const LogoWrapperStyled = styled.a`
  display: flex;
  align-items: center;

  ${({ type }) => type === typesLogo.light && css`
    ${LogoIconStyled} {
      color: ${({ theme }) => theme.colors.white};
    }
    ${LogoLabelStyled} {
      color: ${({ theme }) => theme.colors.white};
    }  
  `}

  ${({ type }) => type === typesLogo.dark && css`
    ${LogoIconStyled} {
      color: ${({ theme }) => theme.colors.arctic};
    }
    ${LogoLabelStyled} {
      color: ${({ theme }) => theme.colors.white};
    }  
  `}


  ${({ type }) => type === typesLogo.mixedDark && css`
    ${LogoIconStyled} {
      color: ${({ theme }) => theme.colors.arctic};
    }
    ${LogoLabelStyled} {
      color: ${({ theme }) => theme.colors.darkNavy};
    }  
  `}

  ${({ type }) => type === typesLogo.mixedLight && css`
    ${LogoIconStyled} {
      color: ${({ theme }) => theme.colors.arctic};
    }
    ${LogoLabelStyled} {
      color: ${({ theme }) => theme.colors.white};
    }  
  `}

  @media (${media.tablet}) {
    ${({ type }) => type === typesLogo.dark && css`
      ${LogoIconStyled} {
        color: ${({ theme }) => theme.colors.darkNavy};
      }
      ${LogoLabelStyled} {
        color: ${({ theme }) => theme.colors.darkNavy};
      }  
    `}    
  }
`

export {
  LogoWrapperStyled,
  LogoIconStyled,
  LogoLabelStyled,
}
