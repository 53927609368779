import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

export const FORMS = {
  CONTACT: 'contact',
}
const formElements = {}
const forms = [
  {
    name: FORMS.CONTACT,
    fields: [
      {
        attrs: {
          name: 'firstName',
        },
      },
      {
        attrs: {
          name: 'lastName',
        },
      },
      {
        attrs: {
          name: 'companyName',
        },
      },
      {
        component: 'select',
        attrs: {
          name: 'companyType',
        },
      },
      {
        attrs: {
          name: 'email',
        },
      },
      {
        attrs: {
          name: 'phone',
        },
      },
      {
        component: 'select',
        attrs: {
          name: 'reason',
        },
      },
      {
        component: 'textarea',
        attrs: {
          name: 'notes',
        },
      },
    ],
  },
]

const NetlifyForm = ({ name, fields }) => {
  const formRef = useRef()

  useEffect(() => {
    formElements[name] = formRef.current
  }, [name])

  return (
    <form
      data-netlify="true"
      method="POST"
      netlify=""
      name={name}
      netlify-honeypot="bot-field"
      ref={formRef}
      hidden
    >
      {fields.map(({ attrs, component: Component = 'input' }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Component {...attrs} key={index} />
      ))}
    </form>
  )
}

NetlifyForm.propTypes = {
  name: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      attrs: PropTypes.any,
      component: PropTypes.any,
    }),
  ).isRequired,
}

const NetlifyForms = () =>
  forms.map(({ name, fields }) => (
    <NetlifyForm {...{ name, fields }} key={name} />
  ))

const tryGetDefaultFormAction = () => {
  try {
    return window.location.origin
  } catch (_) {
    return ''
  }
}

export const useNetlifyForm = (formName) => {
  const formElement = formElements[formName]

  if (!formElement) return {}

  const attributes = {}

  for (let i = 0; i < formElement.attributes.length; i++) {
    const { name, value } = formElement.attributes.item(i)

    attributes[name] = value
  }

  return {
    ...attributes,
    action: formElement.action || tryGetDefaultFormAction(),
  }
}

export default NetlifyForms
