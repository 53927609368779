import { rgba } from 'polished'
import { css } from 'styled-components'

import theme from '../theme'
import media from './MediaQueries'

export const transition = ({
  property = 'all',
  timingFunction = 'ease',
}) => css`
  transition: ${property} 370ms ${timingFunction};
`

export const icon = css`
  display: inline-flex;
  flex-shrink: 0;
  fill: currentColor;
  width: 1em;
  height: 1em;
`

export const fieldReset = css`
  width: 100%;
  appearance: none;
  border: 1px solid ${theme.colors.arctic};
  border-radius: 0;
  background: ${theme.colors.white};
  color: ${theme.colors.darkNavy};
  box-shadow: none;
  font-family: ${theme.fontFamily.maax};
  font-size: ${theme.typography.textStyles.typo3.fontSize[0]};
  line-height: ${theme.typography.textStyles.typo3.lineHeight};

  @media (${media.phone}) {
    font-size: ${theme.typography.textStyles.typo3.fontSize[1]};
  }

  &::placeholder {
    color: ${theme.colors.darkNavy};
  }

  &:focus {
    outline-width: 0;
    border: 1px solid ${theme.colors.arctic};
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &::-ms-expand {
    display: none;
  }

  &:-webkit-autofill {
    -webkit-animation-name: autofill;

    background: transparent;
    box-shadow: none;

    -webkit-animation-fill-mode: both;
  }
`

export const navigationGradient = css`
  &::before {
    z-index: -1;
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-shadow: ${rgba(theme.colors.darkerGray, 0.45)} 0 0 10px 2px;
    border-radius: inherit;
  }
`

export const decorGradient = css`
  position: relative;

  &::after {
    z-index: -1;
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 0;
    width: 90%;
    height: 100%;
    border-radius: 20px;
    box-shadow: 0 8px 20px ${rgba(theme.colors.navy, 0.05)},
      0 10px 20px ${rgba(theme.colors.navy, 0.1)},
      0 20px 20px ${rgba(theme.colors.navy, 0.25)};
    transform: translateX(-50%);
  }
`

export const decorHeading = ({ borderColor = `${theme.colors.cobalt}` }) => css`
  position: relative;
  padding-bottom: ${theme.gutter.gutter32};

  &::after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: ${theme.gutter.gutter10};
    display: block;
    content: '';
    width: ${theme.sizes.decorHeadingWidthPhone};
    height: 3px;
    background: ${borderColor};
  }

  @media (${media.phone}) {
    padding-bottom: ${theme.gutter.gutter70};

    &::after {
      left: 0;
      bottom: ${theme.gutter.gutter36};
      width: ${theme.sizes.decorHeadingWidthTablet};
      transform: none;
    }

    ${(props) =>
      props.center &&
      css`
        &::after {
          left: 50%;
          transform: translateX(-50%);
        }
      `}
  }

  @media (${media.tablet}) {
    &::after {
      width: ${theme.sizes.decorHeadingWidth};
    }
  }
`

export const logoPosition = css`
  position: absolute;
  top: ${theme.gutter.gutter32};
  left: ${theme.gutter.gutter16};

  @media (${media.phone}) {
    left: ${theme.gutter.gutter32};
  }

  @media (${media.tablet}) {
    left: ${theme.gutter.gutter64};
  }
`

export const borderDecor = css`
  &::before {
    z-index: 10;
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: ${theme.colors.blue};
  }
`

export const baseLink = css`
  display: flex;
  justify-content: center;
  font-weight: ${theme.fontWeight.bold};
  letter-spacing: ${theme.letterSpacing.spacing40};
  color: ${theme.colors.white};
  cursor: pointer;
  ${transition}

  @media (${media.phone}) {
    letter-spacing: ${theme.letterSpacing.spacing70};
  }

  @media (${media.tablet}) {
    letter-spacing: ${theme.letterSpacing.spacing20};
    color: ${theme.colors.darkNavy};

    &:hover {
      color: ${theme.colors.arctic};
    }

    &.active {
      color: ${theme.colors.arctic};
    }
  }
`
