
import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '20',
  height: '13.3',
  viewBox: '0 0 20 13.3',
  body: (
    <g key="key-arrow-down">
      <path d="M19.98 2.53L17.63 0 10 8.22 2.37 0 .02 2.53 10 13.3l9.98-10.77z" />
      <path d="M19.98 2.53L17.63 0 10 8.22 2.37 0 .02 2.53 10 13.3l9.98-10.77z" fill="none" />
    </g>
  ),
  displayName: 'ArrowDown',
})
