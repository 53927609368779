import React from 'react'

import Logo from '../../components/Logo'
import { InnerStyled } from '../../common/common'
import { Typo5 } from '../../components/Typography'
import {
  FooterWrapperStyled,
  FooterLogoStyled,
  FooterLinkStyled,
  FooterRouterLinkStyled,
  FooterSocialListStyled,
  FooterSocialItemStyled,
  FooterPrivacyListStyled,
  FooterPrivacyItemStyled,
  FooterHeadingContactStyled,
  FooterHeadingDetailsStyled,
  FooterHeadingSocialStyled,
  FooterAddressLinksStyled,
  FooterAddressDetailsStyled,
  FooterBoxStyled,
} from './styles'

const Footer = () => {
  const contactItems = [
    {
      label: 'info@autoloadit.com',
      href: 'mailto:info@autoloadit.com',
      isUnderlined: true,
    },
    {
      label: '@autoloadit',
      href: 'mailto:info@autoloadit.com',
      isUnderlined: true,
    },
    {
      label: '+44 (0)20 3965 4500',
      href: 'tel:4402039654500',
      isUnderlined: false,
    },
  ]
  const autoloadItems = [
    {
      label: '664 Victoria Road,',
    },
    {
      label: 'South Ruislip,',
    },
    {
      label: 'Middlesex,',
    },
    {
      label: 'United Kingdom,',
    },
    {
      label: 'HA4 0LN',
    },
  ]
  const socialItems = [
    {
      label: 'linkedin',
      href: 'https://www.linkedin.com/company/autoloadit/',
    },
    {
      label: 'twitter',
      href: 'https://twitter.com/AutoLoadIT',
    },
    {
      label: 'youtube',
      href: 'https://www.youtube.com/channel/UCaAkKNZ68xEi2k5JmuGj_Vw',
    },
  ]
  const privacyItems = [
    {
      label: 'Privacy policy',
      to: '/privacy-policy/',
    },
    {
      label: 'Terms and conditions',
      to: '/legal/',
    },
  ]
  return (
    <FooterWrapperStyled>
      <InnerStyled>
        <FooterBoxStyled>
          <FooterLogoStyled>
            <Logo isAlt />
          </FooterLogoStyled>
          <FooterHeadingContactStyled>
            <Typo5>
              Contact us
            </Typo5>
          </FooterHeadingContactStyled>
          <FooterAddressLinksStyled>
            {contactItems.map(({ label, href, isUnderlined }) => (
              <FooterLinkStyled
                key={label}
                href={href}
                isUnderlined={isUnderlined}
              >
                <Typo5>
                  {label}
                </Typo5>
              </FooterLinkStyled>
            ))}
          </FooterAddressLinksStyled>
          <FooterHeadingDetailsStyled>
            <Typo5>
              Autoloadit hq
            </Typo5>
          </FooterHeadingDetailsStyled>
          <FooterAddressDetailsStyled>
            {autoloadItems.map(({ label }) => (
              <Typo5 key={label}>
                {label}
              </Typo5>
            ))}
          </FooterAddressDetailsStyled>
          <FooterHeadingSocialStyled>
            <Typo5>
              Social
            </Typo5>
          </FooterHeadingSocialStyled>
          <FooterSocialListStyled>
            {socialItems.map(({ label, href }) => (
              <FooterSocialItemStyled key={label}>
                <FooterLinkStyled
                  isUnderlined
                  href={href}
                  target="_blank"
                  rel="noopener"
                >
                  <Typo5>
                    {label}
                  </Typo5>
                </FooterLinkStyled>
              </FooterSocialItemStyled>
            ))}
          </FooterSocialListStyled>
          <FooterPrivacyListStyled>
            {privacyItems.map(({ label, to }) => (
              <FooterPrivacyItemStyled key={label}>
                <FooterRouterLinkStyled to={to}>
                  <Typo5>{label}</Typo5>
                </FooterRouterLinkStyled>
              </FooterPrivacyItemStyled>
            ))}
          </FooterPrivacyListStyled>
        </FooterBoxStyled>
      </InnerStyled>
    </FooterWrapperStyled>
  )
}

export default Footer
