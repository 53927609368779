import styled from 'styled-components'

import media from '../../common/MediaQueries'
import { InnerStyled } from '../../common/common'

const SimpleSectionStyled = styled.div`
  margin-top: ${({ theme }) => theme.gutter.gutter150};
  margin-bottom: ${({ theme }) => theme.gutter.gutter32};

  @media (${media.phone}) {
    margin-bottom: ${({ theme }) => theme.gutter.gutter64};
  }

  @media (${media.tablet}) {
    margin-top: ${({ theme }) => theme.gutter.gutter128};
    margin-bottom: ${({ theme }) => theme.gutter.gutter128};
  }
`

const SimpleSectionInnerStyled = styled(InnerStyled)``

export {
  SimpleSectionStyled,
  SimpleSectionInnerStyled,
}
