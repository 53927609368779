import React from 'react'
import PropTypes from 'prop-types'

import {
  SimpleSectionStyled,
  SimpleSectionInnerStyled,
} from './styles'


const SimpleSection = ({
  children,
}) => (
  <SimpleSectionStyled>
    <SimpleSectionInnerStyled>
      {children}
    </SimpleSectionInnerStyled>
  </SimpleSectionStyled>
)

SimpleSection.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SimpleSection
