import styled, { css } from 'styled-components'

import media from '../../common/MediaQueries'
import { transition } from '../../common/Mixins'

const HamburgerLineStyled = styled.span`
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 4px;
  color: ${({ theme }) => theme.colors.darkNavy};
  background: ${({ theme }) => theme.colors.darkNavy};
  ${transition}

  &::before,
  &::after {
    display: block;
    content: '';
    right: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    width: 100%;
    height: 4px;
    transition-duration: .5s;
    background: ${({ theme }) => theme.colors.darkNavy};
  }

  &::before {
    top: calc(-6px - 4px);
  }
  
  &::after {
    bottom: calc(-6px - 4px);
  }

  @media (${media.phone}) {
    &::before {
    top: calc(-8px - 4px);
    }
    
    &::after {
      bottom: calc(-8px - 4px);
    } 
  }
`

const HamburgerStyled = styled.button`
  position: relative;
  width: ${({ theme }) => theme.sizes.hamburgerWidth};
  height: ${({ theme }) => theme.sizes.hamburgerHeight};  
  z-index: 20;
  transform: translateZ(0);
  cursor: pointer;

  ${({ isOpened }) => isOpened && css`
    ${HamburgerLineStyled} {
      background-color: transparent;
      transform-origin: 50% 50%;

      &::before,
      &::after {
        background: ${({ theme }) => theme.colors.white};
      }
    }

    ${HamburgerLineStyled}::before {
      top: 0;
      transform: rotate3d(0, 0, 1, -45deg);
    }

    ${HamburgerLineStyled}::after {
      bottom: 0;
      transform: rotate3d(0, 0, 1, 45deg);
    }
  `}

  @media (${media.phone}) {
    width: ${({ theme }) => theme.sizes.hamburgerWidthTablet};
    height: ${({ theme }) => theme.sizes.hamburgerHeightTablet};  
  }

  @media (${media.tablet}) {
    display: none;
  }
`

export {
  HamburgerStyled,
  HamburgerLineStyled,
}
