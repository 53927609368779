export default {
  DEMO: {
    label: 'demo',
    href: '/',
    to: 'demo',
  },
  SOLUTIONS: {
    label: 'solutions',
    href: '/',
    to: 'solutions',
  },
  BENEFITS: {
    label: 'benefits',
    href: '/',
    to: 'benefits',
  },
  FAQS: {
    label: 'faqs',
    href: '/',
    to: 'faqs',
  },
}
