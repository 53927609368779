import theme from '../theme'

const [smallPhone, phone, tablet, desktop] = theme.breakpoints
const media = {
  smallPhone: `min-width: ${smallPhone}`,
  phone: `min-width: ${phone}`,
  tablet: `min-width: ${tablet}`,
  desktop: `min-width: ${desktop}`,
  maxPhone: `max-width: ${phone}`,
}

export default media

export const customMedia = {
  contactBreakpoint: 'min-width: 1300px',
}
