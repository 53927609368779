import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import _noop from 'lodash/noop'

import {
  PopupModalStyled,
  PopupModalInnerStyled,
  PopupModalHeaderStyled,
  PopupModalContentStyled,
  PopupModalActionStyled,
  PopupModalCloseStyled,
  PopupModalLogoStyled,
  PopupModalBtnLabelStyled,
} from './styles'
import CrossIcon from '../../components/Icons/Cross'
import { Typo6 } from '../../components/Typography'

PopupModalStyled.setAppElement('#___gatsby')

const PopupModal = ({
  closeModal,
  isOpen,
  isAlt,
  logo,
  children,
  popupUrl,
}) => {
  const closeModalCallback = useCallback(() => {
    window.history.go(-1)
  }, [])

  useEffect(() => {
    const onPopState = () => {
      closeModal()
    }

    if (isOpen) {
      if (popupUrl) {
        window.history.replaceState(window.history.state, '', '/')
      }

      window.history.pushState(
        window.history.state,
        '',
        popupUrl || window.location.pathname,
      )

      window.addEventListener('popstate', onPopState)
    }

    return () => {
      window.removeEventListener('popstate', onPopState)
    }
  }, [isOpen, closeModal, popupUrl])

  return (
    <PopupModalStyled isOpen={isOpen} onRequestClose={closeModalCallback}>
      <PopupModalActionStyled isAlt={isAlt}>
        <PopupModalCloseStyled onClick={closeModalCallback}>
          {isAlt && (
            <PopupModalBtnLabelStyled>
              <Typo6>Close</Typo6>
            </PopupModalBtnLabelStyled>
          )}
          <CrossIcon />
        </PopupModalCloseStyled>
      </PopupModalActionStyled>
      <PopupModalInnerStyled>
        <PopupModalHeaderStyled>
          <PopupModalLogoStyled>{logo}</PopupModalLogoStyled>
        </PopupModalHeaderStyled>
        <PopupModalContentStyled>{children}</PopupModalContentStyled>
      </PopupModalInnerStyled>
    </PopupModalStyled>
  )
}

PopupModal.defaultProps = {
  closeModal: _noop,
  children: '',
  logo: '',
  isOpen: false,
  isAlt: false,
  popupUrl: '',
}

PopupModal.propTypes = {
  closeModal: PropTypes.func,
  children: PropTypes.node,
  logo: PropTypes.node,
  isOpen: PropTypes.bool,
  isAlt: PropTypes.bool,
  popupUrl: PropTypes.string,
}

export default PopupModal
