import { createGlobalStyle } from 'styled-components'

import reset from '../common/reset'
import media from '../common/MediaQueries'
import {
  CookieSettingsStyled
} from '../common/common'

export default createGlobalStyle`
  ${reset};

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
  }

  body {
    font-family: 'Maax', sans-serif;
    font-size: 16px;
    line-height: 1.25;
    color: #011556;
  }

  body.ReactModal__Body--open {
    overflow: hidden;

    @media (${media.maxPhone}) {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
    }
  }

  .ReactModalPortal {
    position: relative;
    z-index: 100;
  }

  /* cookies banner */
  .banner-policy-link:hover {
    color: ${({ theme }) => theme.colors.arctic};
  }

  .optanon-toggle-display.cookie-settings-button:hover {
    color: ${({ theme }) => theme.colors.arctic};
  }

  .optanon-allow-all.accept-cookies-button:hover {
    color: ${({ theme }) => theme.colors.navy};
  }

  .optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle button:before {
    top: 0;
    right: -${({ theme }) => theme.gutter.gutter16};
  }

  .banner-policy-link:focus {
    outline: 0;
  }

  ${CookieSettingsStyled} {
    > .optanon-show-settings-popup-wrapper {
      > .optanon-show-settings-button.optanon-toggle-display {
        > .optanon-show-settings-right + .optanon-show-settings-right,
        > .optanon-show-settings-left + .optanon-show-settings-left {
          display: none
        }

        > .optanon-show-settings-middle {
          .optanon-show-settings-middle {
            padding-top: 0;
            border-top: 0;
          }

          .optanon-show-settings-right,
          .optanon-show-settings-left {
            display: none;
          }
        }
      }
    }
  }
`
