import React from 'react'
import PropTypes from 'prop-types'

import {
  ContactSectionStyled,
  ContactSectionMediaStyled,
  ContactSectionFormStyled,
} from './styles'
import { fluidPropType } from '../../constants/proptypes'

const ContactSection = ({
  children,
  car,
}) => (
  <ContactSectionStyled>
    <ContactSectionMediaStyled
      fluid={car}
      objectFit="cover"
      objectPosition="50%"
    />
    <ContactSectionFormStyled>
      {children}
    </ContactSectionFormStyled>
  </ContactSectionStyled>
)

ContactSection.propTypes = {
  children: PropTypes.node.isRequired,
  car: fluidPropType.isRequired,
}

export default ContactSection
