import React from 'react'
import PropTypes from 'prop-types'
import _noop from 'lodash/noop'

import {
  HamburgerStyled,
  HamburgerLineStyled,
} from './styles'

const Hamburger = ({
  onClick,
  isOpened,
}) => (
  <HamburgerStyled
    onClick={onClick}
    isOpened={isOpened}
    aria-label="Open Menu"
  >
    <HamburgerLineStyled />
  </HamburgerStyled>
)

Hamburger.defaultProps = {
  onClick: _noop,
}

Hamburger.propTypes = {
  onClick: PropTypes.func,
  isOpened: PropTypes.bool.isRequired,
}

export default Hamburger
