import '../fonts/fonts.css'
import { rgba } from 'polished'

export default {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    blueGray: '#E2EAF8',
    arctic: '#19CEFF',
    blue: '#00A2FF',
    skyBlue: '#00ADEE',
    cobalt: '#303DDB',
    indigo: '#000DAB',
    navy: '#000CAB',
    darkNavy: '#011556',
    gray: '#C9C9C9',
    gray2: '#9AA3B3',
    darkerGray: '#7B7B7B',
    blueGrayLight: rgba('#E2EAF8', 0.7),
  },
  fontFamily: {
    maax: 'Maax, sans-serif',
  },
  breakpoints: ['375px', '768px', '1050px', '1440px'],
  typography: {
    headings: {
      heading1: {
        fontSize: ['1.5rem', '', '3rem'], // 24px, 48px
        lineHeight: '1.2',
      },
      heading2: {
        fontSize: ['1rem', '', '1.75rem'], // 16px, 28px
        lineHeight: ['1.3', '', '', '1.25'],
      },
      heading3: {
        fontSize: ['1rem', '', '1.625rem'], // 16px, 26px
        lineHeight: ['1.7', '', '1.3'],
      },
      heading4: {
        fontSize: ['1.25rem'], // 20px
        lineHeight: '1.2',
      },
      heading5: {
        fontSize: ['.875rem', '', '1.0625rem'], // 14px, 17px
        lineHeight: '1.6',
      },
      heading6: {
        fontSize: ['1rem', '', '1.25rem'], // 16px, 20px
        lineHeight: '1.2',
      },
    },
    textStyles: {
      typo1: {
        fontSize: ['1.5rem', '', '2.5rem', '0.75rem'], // 24px, 40px, 12px
        lineHeight: '1.2',
      },
      typo2: {
        fontSize: ['.875rem', '', '1.125rem'], // 14px, 18px
        lineHeight: '1.85',
      },
      typo3: {
        fontSize: ['1rem', '', '.9375rem'], // 16px, 15px
        lineHeight: '1',
      },
      typo4: {
        fontSize: ['.875rem'], // 14px
        lineHeight: '1.85',
      },
      typo5: {
        fontSize: ['.8125rem'], // 13px
        lineHeight: ['1.85', '', '2'],
      },
      typo6: {
        fontSize: ['.75rem', '', '.8125rem'], // 12px, 13px
        lineHeight: ['1.25', '', '', '1.85'],
      },
      typo7: {
        fontSize: ['.625rem', '', '.6875rem'], // 10px, 11px
        lineHeight: '1.45',
      },
      typo8: {
        fontSize: ['.625rem'], // 10px
        lineHeight: '1.2',
      },
    },
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  size: {
    footerPrivacyHeight: '10px',
    footerColWidth: '195px',
    footerColLastWidth: '95px',
  },
  letterSpacing: {
    spacing04: '.04em',
    spacing10: '.1em',
    spacing70: '.7em',
    spacing40: '.4em',
    spacing20: '.2em',
    spacing50: '.5em',
  },
  gutter: {
    gutter4: '4px',
    gutter8: '8px',
    gutter10: '10px',
    gutter12: '12px',
    gutter14: '14px',
    gutter16: '16px',
    gutter18: '18px',
    gutter20: '20px',
    gutter24: '24px',
    gutter28: '28px',
    gutter32: '32px',
    gutter36: '36px',
    gutter38: '38px',
    gutter40: '40px',
    gutter48: '48px',
    gutter52: '52px',
    gutter56: '56px',
    gutter64: '64px',
    gutter70: '70px',
    gutter76: '76px',
    gutter82: '82px',
    gutter96: '96px',
    gutter110: '110px',
    gutter112: '112px',
    gutter120: '120px',
    gutter128: '128px',
    gutter140: '140px',
    gutter150: '150px',
    gutter160: '160px',
  },
  button: {
    heightSmall: '34px',
    heightMedium: '42px',
    heightMediumMobile: '40px',
    heightBig: '44px',
    heightBigMobile: '34px',
    heightLarge: '60px',
    menuButtonWidth: '118px',
  },
  form: {
    fieldPhoneHeight: '42px',
    fieldHeight: '44px',
    fieldTextareaPhoneHeight: '84px',
    fieldTextareaHeight: '88px',
  },
  sizes: {
    innerWidth: '1204px',
    modalCloseSize: '60px',
    modalHeaderHeight: '66px',
    hamburgerWidthTablet: '45px',
    hamburgerWidth: '32px',
    hamburgerHeightTablet: '34px',
    hamburgerHeight: '25px',
    menuHeightPhone: '94px',
    menuHeight: '94px',
    menuNavWrapperHeight: '32px',
    vehicleShowcaseNavHeight: '46px',
    vehicleShowcaseNavHeightMobile: '38px',
    vehicleTeaserSpaceBetweenMobile: '16px',
    vehicleTeaserSpaceBetween: '30px',
    footerSocialMaxWidth: '216px',
    heroHeightMobile: '615px',
    heroHeight: '860px',
    heroHeightDesktop: '898px',
    heroPaddingTopPhone: '126px',
    heroPaddingTopTablet: '160px',
    heroPaddingTop: '108px',
    heroHeaderWidth: '708px',
    heroGradientHeightPhone: '120px',
    heroGradientHeight: '145px',
    heroBodyWidth: '584px',
    partnersBoxWidth: '1332px',
    partnersLabelCol: '170px',
    partnerLogoWidth: '140px',
    vehicleTeaserSliderNavSizePhone: '40px',
    vehicleTeaserSliderNavSize: '45px',
    buttonNavigationPhoneSize: '48px',
    buttonNavigationTabletSize: '60px',
    buttonNavigationSize: '70px',
    roundelSizePhone: '74px',
    roundelSize: '83px',
    roundelShadowSizePhone: '74px',
    roundelShadowSize: '134px',
    roundelCenterSizePhone: '46px',
    roundelCenterSize: '83px',
    partnerSectionInfoWidth: '276px',
    partnerSectionIndent: '148px',
    partnerSectionLogoHeightPhone: '24px',
    partnerSectionLogoHeight: '34px',
    partnerSectionLogoMaxHeight: '52px',
    partnerSectionLogoWidth: '154px',
    clientQuotesSliderPaginationPhoneWidth: '32px',
    clientQuotesSliderPaginationPhoneHeight: '288px',
    clientQuotesSliderPaginationTabletWidth: '60px',
    clientQuotesSliderPaginationTabletHeight: '350px',
    clientQuotesSliderPaginationWidth: '150px',
    clientQuotesSliderPaginationHeight: '338px',
    clientQuoteHeadingTabletWidth: '480px',
    clientQuoteHeadingWidth: '424px',
    clientQuoteLogoWidth: '150px',
    clientQuotePaginationCounterSize: '35px',
    contentBoxWidth: '424px',
    contentBoxDescIndentLeft: '112px',
    decorHeadingWidthPhone: '32px',
    decorHeadingWidthTablet: '44px',
    decorHeadingWidth: '50px',
    quotesSliderNavPhoneSize: '48px',
    quotesSliderNavSize: '75px',
    colsSectionAsideWidth: '248px',
    softwareBgDecorWidth: '604px',
    softwareBgDecorPhoneWidth: '286px',
    softwareDecorWidth: '280px',
    softwareLogoHeightPhone: '10px',
    benefitsIconWidth: '40px',
    benefitsHeaderHeight: '48px',
    contactFormWidth: '586px',
    contactFormLoaderSize: '42px',
  },
  ease: {
    ease_in_out_circ: 'cubic-bezier(0.785, 0.135, 0.15, 0.86)',
    ease_out_circ: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
    ease_out_expo: 'cubic-bezier(0.19, 1, 0.22, 1)',
  },
  ratio: {
    ratio53: '53%',
    ratio60: '60%',
    ratioDemo: '58.2%',
  },
}
