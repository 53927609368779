import { useMemo } from 'react'
import media from './MediaQueries'

/* eslint-disable import/prefer-default-export */
export const tryGetImage = src => new Promise((resolve, reject) => {
  const img = document.createElement('img')

  img.onload = () => {
    resolve()
    img.remove()
  }

  img.onerror = () => {
    reject()
    img.remove()
  }

  img.src = src
})

export const matchMedia = typeof window !== 'undefined'
  ? window.matchMedia
  : () => ({ matches: false })

export const isDevelopment = process.env.NODE_ENV === 'development'

export const useTeaserImageSrc = (src, srcPhone, srcSmallPhone) => {
  const phoneMatchMedia = useMemo(() => matchMedia(`(${media.phone})`), [])
  const smallPhoneMatchMedia = useMemo(() => matchMedia(`(${media.smallPhone})`), [])

  if (phoneMatchMedia.matches) {
    return src
  }

  if (smallPhoneMatchMedia.matches) {
    return srcPhone
  }

  return srcSmallPhone
}
