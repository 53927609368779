import React, { useCallback, useState } from 'react'
import _get from 'lodash/get'
import { Form, Field } from 'react-final-form'
import PropTypes from 'prop-types'
import axios from 'axios'
import qs from 'query-string'
import _noop from 'lodash/noop'

import {
  ContactFormWrapperStyled,
  ContactFormStyled,
  ContactHeaderStyled,
  ContactDescStyled,
  ContactFieldsStyled,
  ContactFieldHalfStyled,
  ContactFieldStyled,
  ContactActionStyled,
  ContactSentMsgStyled,
  ContactLoaderStyled,
} from './styles'
import { Heading3, Typo4 } from '../Typography'
import Input from '../Field/Input'
import Textarea from '../Field/Textarea'
import Select from '../Field/Select'
import Button from '../Buttons/Button'
import { useNetlifyForm, FORMS } from '../../global/NetlifyForm'

const companyTypeOptions = [
  {
    label: 'Dealership',
    value: 'Dealership',
  },
  {
    label: 'Auction House',
    value: 'Auction House',
  },
  {
    label: 'Website designer/Software House',
    value: 'Website designer/Software House',
  },
  {
    label: 'Reseller',
    value: 'Reseller',
  },
  {
    label: 'Other',
    value: 'Other',
  },
]

const reasonOptions = [
  {
    label: 'Sales',
    value: 'Sales',
  },
  {
    label: 'Support',
    value: 'Support',
  },
  {
    label: 'Careers',
    value: 'Careers',
  },
  {
    label: 'Other',
    value: 'Other',
  },
]

const ContactForm = ({
  closeModal,
}) => {
  const [isSent, setIsSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { action } = useNetlifyForm(FORMS.CONTACT)
  const onSubmit = useCallback(async data => {
    setIsLoading(true)

    try {
      await axios.post(action, qs.stringify({
        ...data,
        'form-name': FORMS.CONTACT,
      }))

      setIsSent(true)
    } finally {
      setIsLoading(false)
    }
  }, [action])

  return (
    <ContactFormWrapperStyled isSent={isSent}>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          companyType: _get(companyTypeOptions, '0.value'),
          reason: _get(reasonOptions, '0.value'),
        }}
      >
        {({ handleSubmit }) => (
          <ContactFormStyled
            onSubmit={handleSubmit}
          >
            {isSent
              ? (
                <ContactSentMsgStyled>
                  <ContactHeaderStyled center>
                    <Heading3 ad="h2">
                      Your message was sent successfully.
                    </Heading3>
                  </ContactHeaderStyled>
                  <ContactDescStyled>
                    <Typo4>
                      Thank you for the interest you have shown in AutoLoad IT.
                    </Typo4>
                  </ContactDescStyled>
                  <ContactActionStyled>
                    <Button
                      onClick={closeModal}
                      aria-label="Close Modal"
                    >
                      Back to home
                    </Button>
                  </ContactActionStyled>
                </ContactSentMsgStyled>
              ) : (
                <>
                  <ContactHeaderStyled center>
                    <Heading3 ad="h2">
                      To find out more, please complete the form below
                    </Heading3>
                  </ContactHeaderStyled>
                  <ContactDescStyled>
                    <Typo4>
                      For general enquiries you can also contact us at via <a href="mailto:info@autoloadit.com">info@autoloadit.com</a>,
                      or <a href="tel:4402039654500">+44 (0)20 3965 4500</a> - otherwise
                      please complete the form below and one of the team will get back to you as soon as possible.
                    </Typo4>
                  </ContactDescStyled>
                  <ContactFieldsStyled>
                    <ContactFieldHalfStyled>
                      <Field
                        disabled={isLoading}
                        component={Input}
                        name="firstName"
                        id="first-name"
                        label="First Name*"
                        type="text"
                      />
                    </ContactFieldHalfStyled>
                    <ContactFieldHalfStyled>
                      <Field
                        disabled={isLoading}
                        component={Input}
                        name="lastName"
                        id="last-name"
                        label="Last Name*"
                        type="text"
                      />
                    </ContactFieldHalfStyled>
                    <ContactFieldHalfStyled>
                      <Field
                        disabled={isLoading}
                        component={Input}
                        id="company-name"
                        label="Company Name*"
                        type="text"
                        name="companyName"
                      />
                    </ContactFieldHalfStyled>
                    <ContactFieldHalfStyled>
                      <Field
                        disabled={isLoading}
                        component={Select}
                        label="Company Type*"
                        name="companyType"
                        options={companyTypeOptions}
                      />
                    </ContactFieldHalfStyled>
                    <ContactFieldHalfStyled>
                      <Field
                        disabled={isLoading}
                        component={Input}
                        id="email"
                        label="Email*"
                        type="email"
                        name="email"
                      />
                    </ContactFieldHalfStyled>
                    <ContactFieldHalfStyled>
                      <Field
                        disabled={isLoading}
                        component={Input}
                        id="phone"
                        label="Phone number*"
                        type="tel"
                        name="phone"
                      />
                    </ContactFieldHalfStyled>
                    <ContactFieldStyled>
                      <Field
                        disabled={isLoading}
                        component={Select}
                        label="Reason"
                        name="reason"
                        options={reasonOptions}
                      />
                    </ContactFieldStyled>
                    <ContactFieldStyled>
                      <Field
                        disabled={isLoading}
                        component={Textarea}
                        id="notes"
                        label="Notes"
                        type="text"
                        name="notes"
                      />
                    </ContactFieldStyled>
                  </ContactFieldsStyled>
                  <ContactActionStyled>
                    {isLoading
                      ? (
                        <ContactLoaderStyled />
                      ) : (
                        <Button
                          type="submit"
                          aria-label="Submit"
                        >
                          Submit
                        </Button>
                      )}
                  </ContactActionStyled>
                </>
              )}
          </ContactFormStyled>
        )}
      </Form>
    </ContactFormWrapperStyled>
  )
}

ContactForm.defaultProps = {
  closeModal: _noop,
}

ContactForm.propTypes = {
  closeModal: PropTypes.func,
}

export default ContactForm
