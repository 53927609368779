import { css } from 'styled-components'

import { transition, icon } from '../../common/Mixins'
import media from '../../common/MediaQueries'

export const buttonBase = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing20};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: 0 ${({ theme }) => theme.gutter.gutter18};
  cursor: pointer;
  ${transition}

  &::before {
    ${transition}
      position: absolute;
      top: 0;
      left: 50%;
      right: 50%;
      bottom: 0;
      opacity: 0;
      content: '';
  }

  &:hover {
    &::before {
      left: 0;
      right: 0;
      opacity: 1;
    }
  }

  > p {
    z-index: 1;
  }
`

export const filled = css`
  background: ${({ theme }) => theme.colors.arctic};
  color: ${({ theme }) => theme.colors.darkNavy};

  &::before {
    background: ${({ theme }) => theme.colors.indigo};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.arctic};
  }
`

export const outlineLight = css`
  border: 2px solid ${({ theme }) => theme.colors.arctic};
  background: transparent;

  &:hover {
    &::before {
      background: ${({ theme }) => theme.colors.arctic};
    }
  }
`

export const outlineDark = css`
  border: 2px solid ${({ theme }) => theme.colors.cobalt};
  background: transparent;

  &:hover {
    color: ${({ theme }) => theme.colors.arctic};
    &::before {
      background: ${({ theme }) => theme.colors.cobalt};
    }
  }
`

export const withIcon = css`
  padding-top: ${({ theme }) => theme.gutter.gutter16};
  padding-bottom: ${({ theme }) => theme.gutter.gutter16};
  background: ${({ theme }) => theme.colors.cobalt};
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  text-align: left;

  svg {
    ${icon}
    color: ${({ theme }) => theme.colors.arctic};
    font-size: 18px;
    margin-left: ${({ theme }) => theme.gutter.gutter20};
  }

  p {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:hover {
    &::before {
      background: ${({ theme }) => theme.colors.indigo};
    }
  }

  @media (${media.tablet}) {
    p {
      justify-content: center;
    }
  }
`

export const rounded = css`
  background: ${({ theme }) => theme.colors.skyBlue};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 3em;
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing10};
  padding-left: ${({ theme }) => theme.gutter.gutter32};
  padding-right: ${({ theme }) => theme.gutter.gutter32};
  
  &::before {
    border-radius: inherit;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.arctic};
    &::before {
      background: ${({ theme }) => theme.colors.darkNavy};
    }
  }
`

export const small = css`
  height: ${({ theme }) => theme.button.heightSmall};
`

export const medium = css`
  height: ${({ theme }) => theme.button.heightMediumMobile};

  @media (${media.phone}) {
    height: ${({ theme }) => theme.button.heightMedium};
  }
`

export const big = css`
  height: ${({ theme }) => theme.button.heightBigMobile};

  @media (${media.phone}) {
    height: ${({ theme }) => theme.button.heightBig};
  }
`

export const large = css`
  min-height: ${({ theme }) => theme.button.heightLarge};
`
