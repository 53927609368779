import styled from 'styled-components'

import media, { customMedia } from '../../common/MediaQueries'
import { icon, transition } from '../../common/Mixins'

const ContactDetailsStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 13px;

  svg {
    ${icon}
     margin-right: 4px;
    font-size: 16px;
  }

  @media (${customMedia.contactBreakpoint}) {
    position: absolute;
    top: 50%;
    left: calc(${({ theme }) => theme.gutter.gutter160} * 2.0625);
    transform: translateY(-45%);
  }

  @media (${media.desktop}) {
    left: calc(${({ theme }) => theme.gutter.gutter160} * 2.5);
  }
`

const ContactDetailsMailStyled = styled.a`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.gutter.gutter4} ${({ theme }) => theme.gutter.gutter8};
  ${transition}
  
  &:hover {
    color: ${({ theme }) => theme.colors.arctic};
  }

  @media (${customMedia.contactBreakpoint}) {
    margin: 0 0 0 ${({ theme }) => theme.gutter.gutter16};
  }
`

const ContactDetailsPhoneStyled = styled.span`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.gutter.gutter4} ${({ theme }) => theme.gutter.gutter8};
  ${transition}

  @media (${customMedia.contactBreakpoint}) {
    margin: 0;
  }
`

export {
  ContactDetailsStyled,
  ContactDetailsMailStyled,
  ContactDetailsPhoneStyled,
}
