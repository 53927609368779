import styled from 'styled-components'
import { Link } from 'gatsby'

import media from '../../common/MediaQueries'
import { transition, borderDecor } from '../../common/Mixins'

const FooterWrapperStyled = styled.footer`
  position: relative;
  background-color: ${({ theme }) => theme.colors.darkNavy};
  padding: ${({ theme }) => theme.gutter.gutter36} 0;
  color: ${({ theme }) => theme.colors.white};
  ${borderDecor}

  @media (${media.phone}) {
    padding: ${({ theme }) => theme.gutter.gutter64} 0;
  }

  @media (${media.tablet}) {
    padding: ${({ theme }) => theme.gutter.gutter110} 0;
  }
`

const FooterLogoStyled = styled.div`
  margin-bottom: ${({ theme }) => theme.gutter.gutter40};
  justify-self: start;

  @media (${media.phone}) {
    grid-row: 1 / 2;
    grid-column: 1 / 4;
  }

  @media (${media.tablet}) {
    grid-row: 1 / 2;
    grid-column: 1 / 2;

    margin-bottom: 0;

    /* IE */
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
`

const FooterLinkStyled = styled.a`
  display: block;
  text-decoration: ${props => (props.isUnderlined ? 'underline' : 'none')};
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing04};
  ${transition}

  &:hover {
    opacity: 0.5;
  }
`

const FooterRouterLinkStyled = styled(Link)`
  display: block;
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing04};
  ${transition}

  &:hover {
    opacity: 0.5;
  }
`

const FooterHeadingStyled = styled.div`
  margin-bottom: ${({ theme }) => theme.gutter.gutter16};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.blue};
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing10};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media (${media.phone}) {
    grid-row: 2 / 3;
  }

  @media (${media.tablet}) {
    grid-row: 1 / 2;
  }
`

const FooterHeadingContactStyled = styled(FooterHeadingStyled)`
  /* IE */
  -ms-grid-row: 1;
  -ms-grid-column: 2;
`

const FooterHeadingDetailsStyled = styled(FooterHeadingStyled)`
  /* IE */
  -ms-grid-row: 1;
  -ms-grid-column: 3;
`

const FooterHeadingSocialStyled = styled(FooterHeadingStyled)`
  /* IE */
  -ms-grid-row: 1;
  -ms-grid-column: 4;
`

const FooterAddressStyled = styled.address`
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing04};
  padding-bottom: ${({ theme }) => theme.gutter.gutter32};

  ${FooterLinkStyled}:not(:last-child) p {
    margin-bottom: ${({ theme }) => theme.gutter.gutter8};
  }

  @media (${media.phone}) {
    grid-row: 3 / 4;
    padding-bottom: ${({ theme }) => theme.gutter.gutter40};
  }

  @media (${media.tablet}) {
    grid-row: 2 / 4;
    padding-bottom: 0;
  }
`

const FooterAddressLinksStyled = styled(FooterAddressStyled)`
  /* IE */
  -ms-grid-row: 2;
  -ms-grid-column: 2;
`

const FooterAddressDetailsStyled = styled(FooterAddressStyled)`
  /* IE */
  -ms-grid-row: 2;
  -ms-grid-column: 3;
`

const FooterSocialListStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: ${({ theme }) => theme.gutter.gutter40};
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.footerSocialMaxWidth};
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing04};

  @media (${media.phone}) {
    display: block;
    padding-bottom: ${({ theme }) => theme.gutter.gutter40};
    max-width: 100%;
  }

  @media (${media.tablet}) {
    padding-bottom: 0;

    /* IE */
    -ms-grid-row: 2;
    -ms-grid-column: 4;
  }
`

const FooterSocialItemStyled = styled.li`
  margin-bottom: ${({ theme }) => theme.gutter.gutter16};
  width: 50%;

  @media (${media.phone}) {
    margin-bottom: ${({ theme }) => theme.gutter.gutter8};
    width: 100%;
  }
`

const FooterPrivacyListStyled = styled.ul`
  display: flex;

  @media (${media.phone}) {
    grid-row: 4 / 4;
    grid-column: 1 / 4;
  }

  @media (${media.tablet}) {
    grid-row: 2 / 4;
    grid-column: 1 / 2;

    height: 100%;
    align-items: flex-end;

    /* IE */
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
  }
`

const FooterPrivacyItemStyled = styled.li`
  position: relative;

  &:not(:last-child) {
      margin-right: ${({ theme }) => theme.gutter.gutter32};

    &::before {
      position: absolute;
      display: block;
      content: '';
      top: 50%;
      right: -${({ theme }) => theme.gutter.gutter16};
      transform: translateY(-50%);
      height: ${({ theme }) => theme.size.footerPrivacyHeight};
      width: 1px;
      background-color: ${({ theme }) => theme.colors.white};
   }
  }
`

const FooterBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  @media (${media.phone}) {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-columns: 42% 34% 24%;

    grid-template-rows: repeat(4, auto);

    align-items: flex-start;

    text-align: left;
  }

  @media (${media.tablet}) {
    display: grid;
    grid-template-columns:
      auto
      ${({ theme: { size } }) => `
        ${size.footerColWidth}
        ${size.footerColWidth}
        ${size.footerColLastWidth}
    `};
    grid-template-rows: repeat(3, auto);

    /* IE */
    display: -ms-grid;
    -ms-grid-columns: 1fr
      ${({ theme: { size } }) => `
        ${size.footerColWidth}
        ${size.footerColWidth}
        ${size.footerColLastWidth}
    `};
    -ms-grid-rows: repeat(3, auto);
  }
`

export {
  FooterWrapperStyled,
  FooterLogoStyled,
  FooterLinkStyled,
  FooterRouterLinkStyled,
  FooterSocialListStyled,
  FooterSocialItemStyled,
  FooterPrivacyListStyled,
  FooterPrivacyItemStyled,
  FooterBoxStyled,
  FooterHeadingContactStyled,
  FooterHeadingDetailsStyled,
  FooterHeadingSocialStyled,
  FooterAddressLinksStyled,
  FooterAddressDetailsStyled,
}
