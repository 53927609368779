import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import PopupModal from '../PopupModal'
import Menu from '../Menu'
import ContactSection from '../ContactSection'
import ContactForm from '../../components/ContactForm'
import Logo from '../../components/Logo'
import { typesLogo } from '../../components/Logo/styles'
import { fluidPropType } from '../../constants/proptypes'

const ContactWrapper = ({
  car,
  initialContactPopupOpened,
}) => {
  const [isOpened, setIsOpened] = useState(initialContactPopupOpened)

  const closeModal = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])

  const openModal = useCallback(() => {
    setIsOpened(true)
  }, [setIsOpened])

  return (
    <>
      <Menu onClick={openModal} />
      <PopupModal
        popupUrl="/contact"
        closeTimeoutMS={370}
        isOpen={isOpened}
        closeModal={closeModal}
        logo={(
          <Logo
            href="/"
            aria-label="Go to home page"
            type={typesLogo.mixedLight}
          />
        )}
        isAlt
      >
        <ContactSection
          car={car}
        >
          <ContactForm
            closeModal={closeModal}
          />
        </ContactSection>
      </PopupModal>
    </>
  )
}

ContactWrapper.defaultProps = {
  initialContactPopupOpened: false,
}

ContactWrapper.propTypes = {
  car: fluidPropType.isRequired,
  initialContactPopupOpened: PropTypes.bool,
}

export default ContactWrapper
