import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const tryGetInnerHeight = () => {
  try {
    return window.innerHeight
  } catch (_) {
    return 0
  }
}

export const InnerHeightContext = React.createContext(
  tryGetInnerHeight()
)
const { Provider: InnerHeightProvider } = InnerHeightContext

const InnerHeightObserver = ({ children }) => {
  const [innerHeight, setInnerHeight] = useState(tryGetInnerHeight())

  useEffect(() => {
    const callback = () => {
      const newInnerHeight = tryGetInnerHeight()

      if (innerHeight !== newInnerHeight) {
        setInnerHeight(tryGetInnerHeight())
      }
    }

    window.addEventListener('scroll', callback)
    window.addEventListener('resize', callback)
    window.addEventListener('touchstart', callback)
    window.addEventListener('touchmove', callback)

    return () => {
      window.removeEventListener('scroll', callback)
      window.removeEventListener('resize', callback)
      window.removeEventListener('touchstart', callback)
      window.removeEventListener('touchmove', callback)
    }
  })

  return (
    <InnerHeightProvider value={innerHeight}>{children}</InnerHeightProvider>
  )
}

InnerHeightObserver.propTypes = {
  children: PropTypes.node.isRequired,
}

export default InnerHeightObserver
