import styled, { css } from 'styled-components'

import {
  buttonBase,
  filled,
  outlineLight,
  outlineDark,
  withIcon,
  small,
  medium,
  big,
  large,
} from '../styles'
import { KINDS } from '../../../constants/kinds'
import { SIZES } from '../../../constants/sizes'

const ButtonStyled = styled.button`
  ${buttonBase}

  /* Filled */
  ${({ kind }) => kind === KINDS.filled && css`
    ${filled}
  `}

  /* Outline Light */
  ${({ kind }) => kind === KINDS.outlineLight && css`
    ${outlineLight}
  `}

  /* Outline Dark */
  ${({ kind }) => kind === KINDS.outlineDark && css`
    ${outlineDark}
  `} 

  /* WithIcon*/
  ${({ kind }) => kind === KINDS.withIcon && css`
    ${withIcon}
  `} 

  /* Small */
  ${({ size }) => size === SIZES.small && css`
    ${small}
  `}

  /* Medium */
  ${({ size }) => size === SIZES.medium && css`
    ${medium}
  `}

  /* Big */
  ${({ size }) => size === SIZES.big && css`
    ${big}
  `} 

  /* Large */
  ${({ size }) => size === SIZES.large && css`
    ${large}
  `} 
`

export default ButtonStyled
