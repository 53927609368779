import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import Modal from 'react-modal'

import {
  icon,
  transition,
  logoPosition,
  borderDecor,
} from '../../common/Mixins'
import media from '../../common/MediaQueries'

const PopupModalStyled = styled(Modal)`
  z-index: 100;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  overflow: hidden;
  ${borderDecor}

  &.ReactModal__Content--after-open {
    opacity: 1;
  }
`

const PopupModalInnerStyled = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: ${({ theme }) => theme.colors.darkNavy};
  color: ${({ theme }) => theme.colors.white};
`

const PopupModalBtnLabelStyled = styled.span`
  display: none;

  @media (${media.tablet}) {
    display: inline;
    letter-spacing: ${({ theme }) => theme.letterSpacing.spacing20};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    text-transform: uppercase;
    margin-right: ${({ theme }) => theme.gutter.gutter8};
  }
`

const PopupModalCloseStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: calc(${({ theme }) => theme.gutter.gutter16} - 1px);
  cursor: pointer;
  color: ${({ theme }) => theme.colors.arctic};
  ${transition}

  svg {
    ${icon}
    font-size: 20px;
  }

  @media (${media.phone}) {
    svg {
      font-size: 35px;
    }
  }

  @media(${media.tablet}) {
    padding: 0;
    background: ${({ theme }) => rgba(theme.colors.darkNavy, 0.2)};
    color: ${({ theme }) => theme.colors.white};
    width: ${({ theme }) => theme.sizes.modalCloseSize};
    height: ${({ theme }) => theme.sizes.modalCloseSize};  
    
    svg {
      font-size: 20px;
    }

    &:hover {
      background: ${({ theme }) => rgba(theme.colors.darkNavy, 1)};
      color: ${({ theme }) => theme.colors.arctic};
    }
  }
`

const PopupModalActionStyled = styled.div`
  z-index: 101;
  position: absolute;
  top: ${({ theme }) => theme.gutter.gutter20};
  right: 0;

  @media (${media.phone}) {
    top: ${({ theme }) => theme.gutter.gutter16};
    right: ${({ theme }) => theme.gutter.gutter16};
  }

  @media (${media.tablet}) {
    top: 5px;
    right: 0;

    ${props => props.isAlt && css`
      top: ${({ theme }) => theme.gutter.gutter32};
      right: ${({ theme }) => theme.gutter.gutter82};

      ${PopupModalCloseStyled} {
        min-width: ${({ theme }) => theme.button.menuButtonWidth};
        height: ${({ theme }) => theme.button.heightSmall};
        border: 2px solid ${({ theme }) => theme.colors.arctic};

        svg {
          font-size: 10px;
          color: ${({ theme }) => theme.colors.arctic};
        }

        &:hover {
          background: ${({ theme }) => theme.colors.arctic};
          color: ${({ theme }) => theme.colors.white};

          svg {
            color: ${({ theme }) => theme.colors.white};
          }
        }
      }
    `}    
  }
`

const PopupModalHeaderStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${({ theme }) => theme.sizes.modalHeaderHeight};
`

const PopupModalContentStyled = styled.div`
  height: 100%;
`

const PopupModalLogoStyled = styled.div`
  z-index: 1;
  ${logoPosition}
`

export {
  PopupModalStyled,
  PopupModalInnerStyled,
  PopupModalActionStyled,
  PopupModalCloseStyled,
  PopupModalHeaderStyled,
  PopupModalContentStyled,
  PopupModalLogoStyled,
  PopupModalBtnLabelStyled,
}
