import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import _noop from 'lodash/noop'

import Hamburger from '../../components/Hamburger'
import Button from '../../components/Buttons/Button'
import Logo from '../../components/Logo'
import { typesLogo } from '../../components/Logo/styles'
import { Typo1 } from '../../components/Typography'
import { SIZES } from '../../constants/sizes'
import {
  MenuStyled,
  MenuLogoStyled,
  MenuWrapperStyled,
  MenuNavListStyled,
  MenuNavListItemStyled,
  MenuLinkStyled,
  MenuRouterLinkStyled,
  MenuNavWrapperStyled,
  MenuContentBoxStyled,
  MenuContentBoxInnerStyled,
  MenuContentBoxNavStyled,
  MenuActionTabletStyled,
  MenuActionCommonStyled,
  MenuContactDetailsStyled,
} from './styles'
import MENU from '../../constants/menu'
import media from '../../common/MediaQueries'
import { matchMedia } from '../../common/utils'
import { HiddenItemStyled } from '../../common/common'
import ContactDetails from '../../components/ContactDetails'

const menuMap = [MENU.DEMO, MENU.SOLUTIONS, MENU.BENEFITS, MENU.FAQS]

const Menu = ({ onClick }) => {
  const mediaQueryListener = useMemo(() => matchMedia(`(${media.tablet})`), [])
  const [isVisible, setIsVisible] = useState(false)
  const [isSticky, setIsSticky] = useState(false)

  const isNotMainPage =
    typeof window !== 'undefined' ? window.location.pathname !== '/' : false

  const handleClick = () => {
    if (mediaQueryListener.matches) return

    setIsVisible(!isVisible)
  }

  // helps re-render component on iPad
  const [hiddenValue, setHiddenValue] = useState(0)

  const scrollListener = useCallback(() => {
    setIsSticky(window.pageYOffset > 60)
    setHiddenValue(window.pageYOffset)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', scrollListener)
    window.addEventListener('touchmove', scrollListener)

    return () => {
      window.removeEventListener('scroll', scrollListener)
      window.removeEventListener('touchmove', scrollListener)
    }
  }, [scrollListener])

  useEffect(() => {
    const onMatchMediaChange = () => {
      setIsVisible(false)
    }

    mediaQueryListener.addListener(onMatchMediaChange)

    return () => {
      mediaQueryListener.removeListener(onMatchMediaChange)
    }
  }, [mediaQueryListener])

  return (
    <MenuStyled isOpened={isVisible} isSticky={isSticky}>
      <MenuWrapperStyled>
        <HiddenItemStyled>{hiddenValue}</HiddenItemStyled>
        <MenuLogoStyled>
          <Logo type={typesLogo.mixedDark} />
        </MenuLogoStyled>
        <MenuContactDetailsStyled>
          <ContactDetails />
        </MenuContactDetailsStyled>
        <MenuNavWrapperStyled>
          <MenuContentBoxStyled isOpened={isVisible}>
            <MenuContentBoxInnerStyled>
              <MenuContentBoxNavStyled>
                <MenuNavListStyled>
                  {isNotMainPage && (
                    <MenuNavListItemStyled>
                      <MenuRouterLinkStyled to="/">
                        <Typo1>Home</Typo1>
                      </MenuRouterLinkStyled>
                    </MenuNavListItemStyled>
                  )}
                  {menuMap.map(({ label, href, to }) => (
                    <MenuNavListItemStyled key={label}>
                      {!isNotMainPage ? (
                        <MenuLinkStyled
                          to={to}
                          href={`#${to}`}
                          spy
                          hashSpy
                          smooth
                          offset={-100}
                          duration={500}
                          delay={mediaQueryListener.matches ? 0 : 370}
                          onClick={handleClick}
                        >
                          <Typo1>{label}</Typo1>
                        </MenuLinkStyled>
                      ) : (
                        <MenuRouterLinkStyled to={`${href}#${to}`}>
                          <Typo1>{label}</Typo1>
                        </MenuRouterLinkStyled>
                      )}
                    </MenuNavListItemStyled>
                  ))}
                </MenuNavListStyled>
                <MenuActionCommonStyled>
                  <Button
                    size={SIZES.small}
                    onClick={onClick}
                    aria-label="Contact Us"
                  >
                    Contact Us
                  </Button>
                </MenuActionCommonStyled>
              </MenuContentBoxNavStyled>
            </MenuContentBoxInnerStyled>
          </MenuContentBoxStyled>
          <MenuActionTabletStyled>
            <Button
              size={SIZES.small}
              onClick={onClick}
              aria-label="Contact Us"
            >
              Contact Us
            </Button>
          </MenuActionTabletStyled>
          <Hamburger onClick={handleClick} isOpened={isVisible} />
        </MenuNavWrapperStyled>
      </MenuWrapperStyled>
    </MenuStyled>
  )
}

Menu.defaultProps = {
  onClick: _noop,
}

Menu.propTypes = {
  onClick: PropTypes.func,
}

export default Menu
