import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import theme from '../theme'
import GlobalStyle from './GlobalStyle'
import NetlifyForm from './NetlifyForm'
import InnerHeightObserver from './InnerHeightObserver'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: 'AutoloadIT: Stunning Images Power More Sales',
            },
            {
              name: 'viewport',
              // eslint-disable-next-line max-len
              content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0',
            },
          ]}
        >
          <script
            src="https://cookie-cdn.cookiepro.com/consent/6a01786a-948b-4ae5-8b90-ad27d46590a7.js"
            type="text/javascript"
            id="cookie-pro-script"
            charset="UTF-8"
          />
          <script type="text/javascript">
            {`
              function OptanonWrapper() {
                document.querySelectorAll('a').forEach(function (anchor) {
                  try {
                    const anchorUrl = new URL(anchor.href)
                    if (anchorUrl.origin !== window.location.origin) {
                      anchor.rel = "noopener"
                        }
                    } catch (_) {}
                })
              }
            `}
          </script>
        </Helmet>
        <InnerHeightObserver>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <NetlifyForm />
            {children}
          </ThemeProvider>
        </InnerHeightObserver>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
