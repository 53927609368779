import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '500',
  height: '500',
  viewBox: '0 0 500 500',
  body: (
    <path
      // eslint-disable-next-line max-len
      d="M426.7 73.2c-97.6-97.6-255.9-97.6-353.6.1s-97.6 255.9.1 353.6 255.9 97.6 353.6-.1C473.7 379.9 500 316.3 500 250c0-66.4-26.4-129.9-73.3-176.8zm-48.1 275c0 .1 0 .1 0 0l-12.7 12.5a66.64 66.64 0 01-62.9 18c-22.8-6.1-44.6-15.8-64.3-28.8-18.4-11.7-35.4-25.5-50.7-40.9-14.1-14-26.8-29.4-37.9-46-12.1-17.8-21.7-37.2-28.5-57.7-7.8-24-1.3-50.4 16.7-68.1l14.8-14.8c4.1-4.1 10.8-4.2 15 0l46.8 46.8c4.1 4.1 4.2 10.8 0 15l-27.5 27.5c-7.9 7.8-8.9 20.2-2.3 29.2 9.9 13.7 21 26.5 32.9 38.4 13.3 13.4 27.8 25.6 43.3 36.4 9 6.2 21.1 5.2 28.8-2.5l26.6-27c4.1-4.1 10.8-4.2 15 0l46.9 47c4.1 4.2 4.1 10.9 0 15z"      
      key="key-phone"
    />
  ),
  displayName: 'Phone',
})
